import { __assign, __awaiter, __generator, __rest, __spreadArray } from "tslib";
/* eslint-disable @typescript-eslint/no-shadow */
import './index.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import { useRequest } from 'ahooks';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import classNames from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnLoading } from '@/components/cn-loading';
import { Select as NextSelect } from '@/components/fusion';
import { HocBaseComponents, Plugin, pluginManager, flattenDeepChildren, loopDataSource, useNextLocale, } from '@/components/cn-utils';
import { packRequest as request } from '@/components/cn-utils/old-request';
// eslint-disable-next-line import/no-cycle
import { CnReadOnly } from '@/components/cn-read-only';
import { getPopupContainer, dataSourceI18n } from '@cainiaofe/cn-ui-common';
function handleRequestService(requestConfig, remoteUrl) {
    var _this = this;
    var _a = requestConfig || {}, service = _a.service, _b = _a.searchKey, searchKey = _b === void 0 ? 'key' : _b, paramsProps = _a.params, dataProps = _a.data, _c = _a.searchFormat, searchFormatProps = _c === void 0 ? function (params) { return params; } : _c, formatParam = _a.formatParam, _d = _a.formatResult, formatResult = _d === void 0 ? function (res) {
        var _a;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    } : _d, _e = _a.method, method = _e === void 0 ? 'GET' : _e;
    if (isFunction(service)) {
        return function (searchConfig) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                return [2 /*return*/, (_a = service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then(function (data) {
                        var dataSource = formatResult(data);
                        return Array.isArray(dataSource) ? dataSource : [];
                    })];
            });
        }); };
    }
    if (remoteUrl) {
        return function (searchConfig) { return __awaiter(_this, void 0, void 0, function () {
            var searchFormat, params, data, resultData, dataSource;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        searchFormat = formatParam !== null && formatParam !== void 0 ? formatParam : searchFormatProps;
                        params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                            ? searchFormat(__assign(__assign({}, paramsProps), (isNil(searchConfig)
                                ? {}
                                : (_a = {},
                                    _a[searchKey] = searchConfig,
                                    _a))))
                            : paramsProps;
                        data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                            ? searchFormat(__assign(__assign({}, dataProps), (isNil(searchConfig)
                                ? {}
                                : (_b = {},
                                    _b[searchKey] = searchConfig,
                                    _b))))
                            : dataProps;
                        return [4 /*yield*/, request(__assign(__assign({}, requestConfig), { params: params, data: data, url: remoteUrl }))];
                    case 1:
                        resultData = _c.sent();
                        dataSource = formatResult(resultData);
                        return [2 /*return*/, Array.isArray(dataSource) ? dataSource : []];
                }
            });
        }); };
    }
    return undefined;
}
function handleErrorInfo(error) {
    if (!error)
        return {};
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-select-error') }, $i18n.get({
            id: 'TERM.RequestDataError',
            dm: '请求数据异常',
            ns: 'CnAsyncSelect',
        }))),
    };
}
function handleLoading(loading, data) {
    var ContentLoading = function () {
        return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'content-loading') },
            React.createElement(CnLoading, { size: "medium" })));
    };
    var IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    if (loading) {
        return {
            state: 'loading',
        };
    }
    return {
        state: undefined,
    };
}
var maxTagPlaceholder = function (selectedValues, totalValues, tagInline) {
    var renderTriggerContent = function () {
        if (tagInline) {
            return "".concat(selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length, "/").concat(totalValues === null || totalValues === void 0 ? void 0 : totalValues.length);
        }
        return (React.createElement(React.Fragment, null,
            $i18n.get({ id: 'Selected', dm: '已选择', ns: 'CnAsyncSelect' }), "".concat(selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length),
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnAsyncSelect',
            })));
    };
    var trigger = (React.createElement("span", { className: "cn-next-select-tag-compact-inner" }, renderTriggerContent()));
    var labels = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map(function (obj) {
        if (typeof obj === 'object') {
            return obj.label;
        }
        return obj;
    });
    return React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', '));
};
function onSearchClear(run, onSearchClearProps, actionType) {
    onSearchClearProps(actionType, run);
}
var CnAsyncSelectPlugin = function (props, context) {
    var _a;
    var _b, _c, _d;
    var baseSelectRef = React.useRef(null);
    var instanceIdRef = React.useRef(null);
    var trace = window.coneArmsTrace;
    var className = props.className, url = props.remoteUrl, requestConfig = props.requestConfig, initRequestConfig = props.initRequestConfig, readOnly = props.readOnly, _e = props.readOnlyProps, readOnlyProps = _e === void 0 ? {} : _e, onVisibleChangeProps = props.onVisibleChange, _f = props.onSearchClear, onSearchClearProps = _f === void 0 ? function () { } : _f, popupContainer = props.popupContainer, $i18n = props.$i18n, fieldNames = props.fieldNames, otherProps = __rest(props, ["className", "remoteUrl", "requestConfig", "initRequestConfig", "readOnly", "readOnlyProps", "onVisibleChange", "onSearchClear", "popupContainer", "$i18n", "fieldNames"]);
    // useRequest 相关配置
    var _g = requestConfig || {}, service = _g.service, searchFormat = _g.searchFormat, ConfighUrl = _g.url, otherConfig = __rest(_g, ["service", "searchFormat", "url"]);
    var remoteUrl = ConfighUrl || url;
    var insertSelectProps = {};
    var requestService = (_b = handleRequestService(requestConfig, remoteUrl)) !== null && _b !== void 0 ? _b : function () {
        return Promise.resolve([]);
    };
    var initRequestService = handleRequestService(initRequestConfig, initRequestConfig === null || initRequestConfig === void 0 ? void 0 : initRequestConfig.url);
    var _h = useRequest(requestService, __assign({ ready: !!(remoteUrl || service) }, otherConfig)), run = _h.run, mutate = _h.mutate, error = _h.error, _j = _h.data, data = _j === void 0 ? [] : _j, loading = _h.loading;
    var _k = React.useState(1), forceUpdate = _k[1];
    // 为了给外部正确使用 forceUpdate
    if (baseSelectRef.current) {
        baseSelectRef.current.forceUpdate = function () {
            forceUpdate(function (s) { return (s + 1) % 32; });
        };
    }
    React.useImperativeHandle(context.ref, function () {
        var _a;
        return __assign({ mutateDataSource: mutate, sandRequest: run }, ((_a = baseSelectRef === null || baseSelectRef === void 0 ? void 0 : baseSelectRef.current) !== null && _a !== void 0 ? _a : {}));
    });
    var onVisibleChange = function (visible, type) {
        var _a, _b, _c;
        if (isFunction(onVisibleChangeProps)) {
            onVisibleChangeProps(visible, type, run);
        }
        try {
            if (!trace || !trace.initCustomEventReport)
                return;
            var commonReportParams = {
                c6: ((_a = otherProps === null || otherProps === void 0 ? void 0 : otherProps.dataSource) === null || _a === void 0 ? void 0 : _a.length) ||
                    ((_b = insertSelectProps === null || insertSelectProps === void 0 ? void 0 : insertSelectProps.dataSource) === null || _b === void 0 ? void 0 : _b.length),
                c7: !!(remoteUrl || service),
                c8: otherProps.mode || 'multiple',
                c9: (_c = otherProps.value) === null || _c === void 0 ? void 0 : _c.toString(),
            };
            if (!visible) {
                var newReportIns = trace.initCustomEventReport({
                    // 事务id
                    taskName: 'cn-component.cn-ui.asyncSelect',
                    autoReportStart: false,
                    // 可同时设置其他上报时需要附带的参数 c6 ~ c10
                });
                // 如果要进行跨子组件进行上报，建议传递instanceId进行事务标记，然后进行上报
                var instanceId = newReportIns.getInstanceId();
                instanceIdRef.current = instanceId;
                trace.reportCustomEventStart(instanceId, commonReportParams);
            }
            else if (instanceIdRef.current) {
                trace.reportCustomEventEnd(instanceIdRef.current, commonReportParams);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    React.useEffect(function () {
        var _a;
        var value = props.value, useDetailValue = props.useDetailValue, filterLocal = props.filterLocal;
        if (useDetailValue)
            return;
        if (filterLocal && !isFunction(initRequestService))
            return;
        if (!isNumber(value) && !isString(value) && !(value instanceof Array))
            return;
        var listValue = value instanceof Array ? value : [value];
        var dataMap = flattenDeepChildren(data.concat((_a = otherProps.dataSource) !== null && _a !== void 0 ? _a : [])).reduce(function (pre, cur) {
            pre[cur.value] = cur;
            return pre;
        }, {});
        var newValue = listValue.filter(function (item) { return !dataMap[item]; });
        if (isEmpty(newValue))
            return;
        if (!isFunction(initRequestService)) {
            newValue.forEach(function (item) {
                requestService(item).then(function (data) {
                    mutate(function (dataSource) {
                        if (dataSource === void 0) { dataSource = []; }
                        var safeDataSource = Array.isArray(dataSource) ? dataSource : [];
                        return __spreadArray(__spreadArray([], safeDataSource, true), data, true);
                    });
                });
            });
        }
        else {
            initRequestService(value).then(function (data) {
                mutate(function (dataSource) {
                    var safeDataSource = Array.isArray(dataSource) ? dataSource : [];
                    return __spreadArray(__spreadArray([], safeDataSource, true), data, true);
                });
            });
        }
    }, [props.value]);
    insertSelectProps.dataSource = data;
    var errorInfo = handleErrorInfo(error);
    var loadingInfo = handleLoading(loading, data);
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    var enableOnSearch = otherProps.showSearch &&
        !otherProps.onSearch &&
        otherProps.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.onSearch = function (inputValue) {
            run(inputValue);
        };
    }
    insertSelectProps.isPreview = readOnly;
    insertSelectProps.renderPreview = function (values) {
        return (React.createElement(CnReadOnly, __assign({ type: "enum", value: values }, __assign(__assign({}, pick(props, [
            'addonBefore',
            'innerBefore',
            'addonTextBefore',
            'addonTextAfter',
            'innerAfter',
            'addonAfter',
        ])), readOnlyProps), { 
            // 大部分业务场景内容都在8行以内，默认8行可以解决大部分情况
            line: readOnlyProps.line || 8 })));
    };
    var onSearchClearCallBack = React.useMemo(function () {
        return onSearchClear.bind(null, run, onSearchClearProps);
    }, []);
    var dataSource = 'dataSource' in props ? props.dataSource : data;
    if (fieldNames) {
        dataSource = loopDataSource(dataSource, fieldNames);
    }
    dataSourceI18n(dataSource);
    // 处理开启 isArrayValue 后，兼容 value 为数组的情况
    var componentValue = props.value;
    if (props.isArrayValue &&
        Array.isArray(props === null || props === void 0 ? void 0 : props.value) &&
        ((_c = props === null || props === void 0 ? void 0 : props.value) === null || _c === void 0 ? void 0 : _c.length) &&
        ((props === null || props === void 0 ? void 0 : props.mode) === 'single' || (props === null || props === void 0 ? void 0 : props.mode) === undefined)) {
        componentValue = props.value[0];
    }
    // 处理开启 isArrayValue 后，兼容 defaultValue 为数组的情况
    var componentDefaultValue = props.defaultValue;
    if (props.isArrayValue &&
        Array.isArray(props === null || props === void 0 ? void 0 : props.defaultValue) &&
        ((_d = props === null || props === void 0 ? void 0 : props.defaultValue) === null || _d === void 0 ? void 0 : _d.length) &&
        ((props === null || props === void 0 ? void 0 : props.mode) === 'single' || (props === null || props === void 0 ? void 0 : props.mode) === undefined)) {
        componentDefaultValue = props.defaultValue[0];
    }
    if (componentValue) {
        insertSelectProps.value = componentValue;
    }
    return {
        props: __assign(__assign(__assign(__assign(__assign({}, props), { className: classNames((_a = {
                    'cn-ui-async-select': true
                },
                _a[className] = !!className,
                _a)), 'data-name': 'CnAsyncSelect', ref: baseSelectRef, maxTagPlaceholder: function (selectedValues, totalValues) {
                return maxTagPlaceholder(selectedValues, totalValues, props.tagInline);
            }, popupContainer: getPopupContainer(popupContainer) }), insertSelectProps), omit(otherProps, ['$i18n'])), { dataSource: dataSource, onVisibleChange: onVisibleChange, onSearchClear: onSearchClearCallBack, defaultValue: componentDefaultValue, onChange: function (value, actionType, item) {
                if (isFunction(props === null || props === void 0 ? void 0 : props.onChange)) {
                    var currentValue = value;
                    // isArrayValue 为 true 时，value 不为 数组，需要转换为数组
                    if ((props === null || props === void 0 ? void 0 : props.isArrayValue) && !Array.isArray(value)) {
                        currentValue = [value];
                    }
                    // isArrayValue 为 true 时，mode 为 single 时，清空时，value 需要转换为 undefined
                    if ((props === null || props === void 0 ? void 0 : props.isArrayValue) &&
                        ((props === null || props === void 0 ? void 0 : props.mode) === 'single' || props.mode === undefined) &&
                        actionType === 'clear' &&
                        value === undefined) {
                        currentValue = undefined;
                    }
                    props === null || props === void 0 ? void 0 : props.onChange(currentValue, actionType, item);
                }
            } }),
        context: context,
    };
};
export var SelectPlugin = React.forwardRef(function (props, ref) {
    var plugin = React.useMemo(function () {
        var plugin = new Plugin('CnAsyncSelect', pluginManager);
        plugin.setBasicsPlugin([
            function (props, context) {
                return {
                    props: props,
                    context: __assign(__assign({}, context), { ref: ref }),
                };
            },
            CnAsyncSelectPlugin,
        ]);
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnAsyncSelect')
            .map(function (item) { return item.method; }));
        return plugin;
    }, []);
    var plugins = plugin.getPlugin();
    var locale = useNextLocale('Select');
    if (plugins.length === 0) {
        return React.createElement(NextSelect, __assign({}, props, { locale: locale, ref: ref }));
    }
    return HocBaseComponents(NextSelect, {
        props: __assign(__assign({}, props), { locale: locale }),
        plugins: plugins,
    });
});
SelectPlugin.defaultProps = {
    maxTagCount: 2,
    tagInline: true,
    filterLocal: true,
    popupClassName: 'cn-async-select-popup',
};
SelectPlugin.displayName = 'Select';
