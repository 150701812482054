/**
 * 判断字符串的第一位是否是数字
 * @param {string} input - 输入的字符串
 * @returns {boolean} - 如果第一位是数字返回 true，否则返回 false
 */
export function isFirstCharDigit(input) {
    if (typeof input !== 'string' || input.length === 0) {
        return false;
    }
    var firstChar = input.charAt(0);
    return /^[0-9]$/.test(firstChar);
}
