import { __assign, __rest } from "tslib";
import './cn-drawer.scss';
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Drawer } from '@/components/fusion';
import { CnCard } from '@/components/cn-card';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { CnDrawerFooter } from '../cn-drawer-footer';
import { NextI18nConfigProvider } from '@/components/cn-utils';
import { CnPageScrollDomSelector, withNativeProps, } from '@cainiaofe/cn-ui-common';
function CnDrawer(props) {
    var _a;
    var children = props.children, _b = props.size, size = _b === void 0 ? 'small' : _b, footer = props.footer, onOk = props.onOk, onCancel = props.onCancel, okProps = props.okProps, cancelProps = props.cancelProps, $i18n = props.$i18n, className = props.className, embeddable = props.embeddable, noCard = props.noCard, transparencyMask = props.transparencyMask, popupContainer = props.popupContainer, rest = __rest(props, ["children", "size", "footer", "onOk", "onCancel", "okProps", "cancelProps", "$i18n", "className", "embeddable", "noCard", "transparencyMask", "popupContainer"]);
    var wrapperRef = React.useRef();
    var hasCard = useCallback(function () {
        var _a;
        if (noCard) {
            return true;
        }
        if (embeddable) {
            return true;
        }
        var types = [];
        if (Array.isArray(children) && children.length > 0) {
            types = children.map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
        }
        else {
            types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
        }
        if (!types)
            return false;
        return types.includes('CnCard');
    }, [children, embeddable]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: wrapperRef, style: { display: 'none' } }),
        withNativeProps(props, React.createElement(Drawer, __assign({ "data-testid": "CnDrawer", "data-name": "CnDrawer", wrapperClassName: classnames({
                'cn-ui-drawer-transparencyMask': transparencyMask,
            }), popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : (function () {
                var _a;
                return ((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.closest(CnPageScrollDomSelector)) ||
                    document.body;
            }) }, rest, { className: classnames((_a = {
                    'cn-ui-drawer': true
                },
                _a["cn-ui-drawer-".concat(size)] = size,
                _a)) }),
            React.createElement(NextI18nConfigProvider, null,
                React.createElement(CnScrollBar, { className: "cn-ui-drawer-wrapper" },
                    React.createElement("div", { className: classnames({
                            'cn-ui-drawer-content': true,
                            'cn-ui-drawer-content-has-footer': footer,
                        }) }, hasCard() ? children : React.createElement(CnCard, null, children)),
                    React.createElement(CnDrawerFooter, { footer: footer, onOk: onOk, okProps: okProps, onCancel: onCancel, cancelProps: cancelProps })))))));
}
CnDrawer.displayName = 'CnDrawer';
export default CnDrawer;
