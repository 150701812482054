// 第三方依赖
import pick from 'lodash/pick';
import { useControllableValue } from 'ahooks';
import { useControlled } from '@cainiaofe/cn-ui-common';
// 当前目录的相对依赖
import { useControlColumnsValue } from './use-control-columns-value';
import { useControlValue } from './use-control-value';
import { useUnitValue } from './use-unit-value';
export function useDelegateHub(props) {
    var _a, _b, _c;
    // const [localColumns, setLocalColumns] = React.useState(props.columns);
    // const preColumns = React.useRef();
    var sortCustom = props.sortCustom;
    var _d = useUnitValue(props, {
        defaultValue: [],
        valuePropName: 'dataSource',
    }), dataSource = _d[0], setDataSource = _d[1];
    var _e = useUnitValue(props, {
        defaultValue: 'id',
        valuePropName: 'primaryKey',
    }), primaryKey = _e[0], setPrimaryKey = _e[1];
    var _f = useControlValue((sortCustom || {}), {
        defaultValuePropName: (props === null || props === void 0 ? void 0 : props.defaultSorts) || [],
        valuePropName: 'sorts',
        changePropName: 'onChangeSorts',
    }), sorts = _f[0], setSorts = _f[1];
    var _g = useControlValue(props, {
        defaultValuePropName: false,
        valuePropName: 'loading',
        changePropName: 'loadingChange',
    }), loading = _g[0], setLoading = _g[1];
    // const [size, setSize] = useControlValue<SizeType>(props as unknown as Obj, {
    //   defaultValuePropName: props?.defaultSize ?? 'medium',
    //   valuePropName: 'size',
    //   changePropName: 'toolbar.sizeChange',
    // });
    var _h = useControlled(props.size
        ? {
            value: props.size,
            onChange: (_a = props.toolbar) === null || _a === void 0 ? void 0 : _a.sizeChange,
        }
        : {}), size = _h[0], setSize = _h[1];
    var _j = useControlValue(props, {
        defaultValuePropName: (_b = props === null || props === void 0 ? void 0 : props.defaultIsFullScreen) !== null && _b !== void 0 ? _b : false,
        valuePropName: 'isFullScreen',
        changePropName: 'toolbar.screenChange',
    }), fullScreen = _j[0], setFullScreen = _j[1];
    var _k = useControlValue(props, {
        defaultValuePropName: (_c = props === null || props === void 0 ? void 0 : props.defaultIsZebra) !== null && _c !== void 0 ? _c : false,
        valuePropName: 'isZebra',
        changePropName: 'toolbar.zebraChange',
    }), zebra = _k[0], setZebra = _k[1];
    var _l = useControllableValue(pick(props.rowDetail, ['defaultOpenKeys', 'openKeys', 'onChangeOpenKeys']), {
        defaultValue: [],
        valuePropName: 'openKeys',
        trigger: 'onChangeOpenKeys',
    }), openKeys = _l[0], setOpenKeys = _l[1];
    var _m = useControlColumnsValue(props), columns = _m[0], setColumns = _m[1];
    return {
        dataSource: dataSource,
        setDataSource: setDataSource,
        primaryKey: primaryKey,
        setPrimaryKey: setPrimaryKey,
        sorts: sorts,
        setSorts: setSorts,
        loading: loading,
        setLoading: setLoading,
        size: size,
        setSize: setSize,
        fullScreen: fullScreen,
        setFullScreen: setFullScreen,
        zebra: zebra,
        setZebra: setZebra,
        columns: columns,
        setColumns: setColumns,
        openKeys: openKeys,
        setOpenKeys: setOpenKeys,
    };
}
