import { __assign, __extends, __rest } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import cloneDeep from 'lodash/cloneDeep';
import NextNumberPicker from './number-picker';
import './range-number-picker.scss';
var CnRangeNumberPicker = /** @class */ (function (_super) {
    __extends(CnRangeNumberPicker, _super);
    function CnRangeNumberPicker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isFocus: false,
        };
        _this.handleBlur = function () {
            _this.setState({ isFocus: false });
        };
        _this.handleFocus = function () {
            _this.setState({ isFocus: true });
        };
        _this.onFirstChange = function (value) {
            var _a = _this.props, oldValue = _a.value, onChange = _a.onChange;
            var secondNumber = Array.isArray(oldValue) ? oldValue[1] : null;
            onChange && onChange([value, secondNumber]);
        };
        _this.onSecondChange = function (value) {
            var _a = _this.props, oldValue = _a.value, onChange = _a.onChange;
            var firstNumber = Array.isArray(oldValue) ? oldValue[0] : null;
            onChange && onChange([firstNumber, value]);
        };
        return _this;
    }
    CnRangeNumberPicker.prototype.render = function () {
        var isFocus = this.state.isFocus;
        var _a = this.props, _b = _a.defaultValue, defaultValue = _b === void 0 ? [] : _b, state = _a.state, readOnly = _a.readOnly, className = _a.className, style = _a.style, firstNumberPickerProps = _a.firstNumberPickerProps, secondNumberPickerProps = _a.secondNumberPickerProps, 
        // other
        onChange = _a.onChange, otherProps = __rest(_a, ["defaultValue", "state", "readOnly", "className", "style", "firstNumberPickerProps", "secondNumberPickerProps", "onChange"]);
        var classes = classNames({
            'cn-ui-range-number-picker-error': state === 'error',
            'cn-ui-range-number-picker-focus': isFocus,
            'cn-ui-range-number-picker-disabled': otherProps.disabled,
            'cn-ui-range-number-picker-readonly': !!readOnly,
        }, 'cn-ui-range-number-picker', CN_UI_HASH_CLASS_NAME, className);
        // 组装前后输入框的 props
        var firstProps = cloneDeep(firstNumberPickerProps || {});
        var secondProps = cloneDeep(secondNumberPickerProps || {});
        var value = this.props.value || [];
        if (Array.isArray(value)) {
            firstProps.value = value[0];
            secondProps.value = value[1];
        }
        if (Array.isArray(defaultValue)) {
            firstProps.defaultValue = defaultValue[0];
            secondProps.defaultValue = defaultValue[1];
        }
        // 预览状态展示
        if (readOnly) {
            var firstBigNumberValue = new BigNumber(firstProps.value);
            var firstDisplayValue = firstBigNumberValue.toString();
            if (!firstBigNumberValue.isNaN()) {
                if (!isNaN(otherProps.precision)) {
                    firstDisplayValue = firstBigNumberValue.toFixed(Number(otherProps.precision));
                }
                if (otherProps.format) {
                    firstDisplayValue = otherProps.format(firstDisplayValue);
                }
            }
            else {
                firstDisplayValue = '-';
            }
            var secondBigNumberValue = new BigNumber(secondProps.value);
            var secondDisplayValue = secondBigNumberValue.toString();
            if (!secondBigNumberValue.isNaN()) {
                if (!isNaN(otherProps.precision)) {
                    secondDisplayValue = secondBigNumberValue.toFixed(Number(otherProps.precision));
                }
                if (otherProps.format) {
                    secondDisplayValue = otherProps.format(secondDisplayValue);
                }
            }
            else {
                secondDisplayValue = '-';
            }
            return (React.createElement("div", { "data-name": "CnRangeNumberPicker", className: classes },
                React.createElement("div", null, firstDisplayValue),
                React.createElement("span", { className: "split-char" }, "-"),
                React.createElement("div", null, secondDisplayValue)));
        }
        // 编辑状态展示
        return (React.createElement("div", { "data-name": "CnRangeNumberPicker", className: classes, style: style, onBlur: this.handleBlur, onFocus: this.handleFocus },
            React.createElement(NextNumberPicker, __assign({ prefix: "cn-next-", onChange: this.onFirstChange, role: "firstNumberPicker" }, otherProps, firstProps, { placeholder: firstProps.placeholder ||
                    otherProps.placeholder ||
                    $i18n.get({ id: 'TERM.Enter', dm: '请输入', ns: 'CnNumberPicker' }) })),
            React.createElement("span", { className: "split-char" }, "-"),
            React.createElement(NextNumberPicker, __assign({ prefix: "cn-next-", onChange: this.onSecondChange, role: "secondNumberPicker" }, otherProps, secondProps, { placeholder: secondProps.placeholder ||
                    otherProps.placeholder ||
                    $i18n.get({ id: 'TERM.Enter', dm: '请输入', ns: 'CnNumberPicker' }) }))));
    };
    CnRangeNumberPicker.displayName = 'CnRangeNumberPicker';
    return CnRangeNumberPicker;
}(React.Component));
export { CnRangeNumberPicker };
