import { useState, useEffect } from 'react';
export var useResizeObserver = function (ref) {
    var _a = useState(undefined), size = _a[0], setSize = _a[1];
    useEffect(function () {
        var element = ref.current;
        if (!element)
            return;
        var resizeObserver = new ResizeObserver(function (entries) {
            if (!entries || entries.length === 0)
                return;
            var _a = entries[0].contentRect, width = _a.width, height = _a.height;
            setSize({ width: width, height: height });
        });
        resizeObserver.observe(element);
        return function () {
            resizeObserver.unobserve(element);
        };
    }, [ref]);
    return size;
};
