import { __assign } from "tslib";
import React from 'react';
import { CnOSSImport } from '@/components/cn-oss-import';
import { CnFormArrayBase } from '@/form/cn-form-array-base';
export var CnArrayTableImport = function (props) {
    var array = CnFormArrayBase.useArray();
    var field = array.field;
    if (field && (field.pattern === 'editable' || field.pattern === 'disabled')) {
        return React.createElement(CnOSSImport, __assign({}, props));
    }
    return null;
};
