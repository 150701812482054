export function addEventListener(target, eventType, cb, option) {
    /* eslint camelcase: 2 */
    var callback = cb;
    if (target.addEventListener) {
        target.addEventListener(eventType, callback, option);
    }
    return {
        remove: function () {
            if (target.removeEventListener) {
                target.removeEventListener(eventType, callback);
            }
        },
    };
}
export function getClientSize() {
    var width = document.documentElement.clientWidth;
    var height = window.innerHeight || document.documentElement.clientHeight;
    return {
        width: width,
        height: height,
    };
}
