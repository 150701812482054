import { __assign, __rest } from "tslib";
import './view/layout.scss';
import classNames from 'classnames';
import React from 'react';
import { useFormLabelAlign, withNativeProps } from '@cainiaofe/cn-ui-common';
import { useFormLabelCol } from './hooks/use-form-label-col';
import { FormLayoutContext } from './context/form-layout-context';
import { useFormLayout } from './hooks';
export { useFormLayout };
export var CnFormLayout = function (props) {
    var _a;
    var children = props.children, className = props.className, style = props.style, labelColProps = props.labelCol, _b = props.labelAlign, labelAlignProps = _b === void 0 ? 'top' : _b, filterDisplay = props.filterDisplay, restProps = __rest(props, ["children", "className", "style", "labelCol", "labelAlign", "filterDisplay"]);
    var layoutMode = restProps.layoutMode;
    // 表单字段标题宽度
    var labelCol = useFormLabelCol({
        labelAlign: labelAlignProps,
        labelCol: labelColProps,
    });
    // 表单字段布局模式
    var labelAlign = useFormLabelAlign(labelAlignProps);
    // 2024.08.22 设计规范定义在单列布局下，labelAlign 固定为 top，提升表单阅读体验，所以这里做了特殊处理
    if (['single-col', 'single-left'].includes(layoutMode)) {
        labelAlign = 'top';
    }
    return withNativeProps(props, React.createElement("div", { "data-name": "CnFormLayout", style: style, className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-layout', 'cn-ui-form-layout', (_a = {
                'cn-ui-form-layout--single-center': layoutMode === 'single-col',
                'cn-ui-form-layout--single-left': layoutMode === 'single-left'
            },
            _a["cn-ui-form-".concat(restProps.size)] = restProps.size,
            _a)) },
        React.createElement(FormLayoutContext.Provider, { value: __assign(__assign(__assign({}, restProps), { labelCol: labelCol, labelAlign: labelAlign }), (filterDisplay === 'flex'
                ? {
                    filterDisplay: filterDisplay,
                    removeEmptyLabel: true,
                }
                : {})) }, children)));
};
CnFormLayout.useFormLayout = useFormLayout;
/**
 * 由于 CnFormLayout.useFormDeepLayout 是内部组件，不对外暴露，同时低码、源码内部并未消费，所以返回空对象，并标记为废弃
 * @deprecated 弃用时间：2024-03-13
 */
export var useFormDeepLayout = function () { return ({}); };
// @ts-ignore 忽略废弃标记
CnFormLayout.useFormDeepLayout = useFormDeepLayout;
/**
 * 由于 CnFormLayout.useFormShallowLayout 是内部组件，不对外暴露，同时低码、源码内部并未消费，所以返回空对象，并标记为废弃
 * @deprecated 弃用时间：2024-03-13
 */
export var useFormShallowLayout = function () { return ({}); };
// @ts-ignore 忽略废弃标记
CnFormLayout.useFormShallowLayout = useFormShallowLayout;
