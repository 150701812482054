import * as React from 'react';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import $i18n, { pandaEvent } from 'panda-i18n';
import NEXT_LOCALE from '../../../locales/cn-next';
/**
 * 用于控制 useValue 能力
 * @param props
 * @param defaultValue
 * @param options
 */
export function useValueState(props, defaultValue, options) {
    var formatter = (options || {}).formatter;
    var isControlled = 'value' in props;
    var initValue = React.useMemo(function () {
        var v = props.defaultValue || defaultValue;
        if (isControlled) {
            v = props.value;
        }
        if (isNil(v)) {
            v = defaultValue;
        }
        if (typeof formatter === 'function') {
            v = formatter(v);
        }
        return v;
    }, []);
    var _a = React.useState(initValue), value = _a[0], setValue = _a[1];
    React.useEffect(function () {
        if (isControlled && value !== props.value) {
            var v = isNil(props.value) ? defaultValue : props.value;
            if (typeof formatter === 'function') {
                v = formatter(v);
            }
            setValue(v);
        }
    }, [props.value]);
    return [value, setValue, isControlled];
}
export function useDerivedValue(props, defaultValue) {
    var isControlled = 'value' in props;
    var initValue = React.useMemo(function () {
        var v = props.defaultValue || defaultValue;
        if (isControlled) {
            v = props.value;
        }
        if (isNil(v)) {
            v = defaultValue;
        }
        return v;
    }, []);
    var _a = React.useState(initValue), value = _a[0], setValue = _a[1];
    var _b = React.useState(null), prevValue = _b[0], setPrevValue = _b[1];
    if (isControlled && !isEqual(prevValue, props.value)) {
        setValue(isNil(props.value) ? defaultValue : props.value);
        setPrevValue(props.value);
    }
    return [value, setValue, isControlled];
}
/** 使用next组件的翻译文案，并支持自动监听语种的变化 */
export var useNextLocale = function (componentName) {
    var _a = React.useState(function () {
        var _lang = $i18n.getLang();
        return NEXT_LOCALE[_lang] && NEXT_LOCALE[_lang][componentName]
            ? NEXT_LOCALE[_lang][componentName]
            : null;
    }), locale = _a[0], setLocale = _a[1];
    pandaEvent.on('CHANGE_LANG', function (_lang) {
        setLocale(NEXT_LOCALE[_lang] && NEXT_LOCALE[_lang][componentName]
            ? NEXT_LOCALE[_lang][componentName]
            : null);
    });
    return locale;
};
