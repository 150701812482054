import padStart from 'lodash/padStart';
import { isFirstCharDigit } from './is-first-char-digit';
/**
 * 如果字符串满足以下条件，则在字符串前补 0
 * 1. 第一位是数字
 * 2. 字符串长度小于6
 *
 * @param {string} workNo - 员工工号
 */
export var workNoPadZero = function (workNo) {
    return isFirstCharDigit(workNo) ? padStart(workNo, 6, '0') : workNo;
};
