import './scss/index.scss';
import './scss/select-menu.scss';
import { withI18n } from 'panda-i18n';
import { componentName } from './const';
// eslint-disable-next-line import/no-cycle
import { AutoComplete as PureAutoComplete } from './view/auto-complete';
import { SelectPlugin } from './view';
import locale from '@/locales';
export var CnAsyncSelect = withI18n(SelectPlugin, {
    componentName: componentName,
    locale: locale,
    forwardRef: true,
});
CnAsyncSelect.displayName = componentName;
// TODO: onecode AutoComplete 一码多端&移动端 暂不支持，后续考虑是否支持
CnAsyncSelect.AutoComplete = PureAutoComplete;
export * from './types';
