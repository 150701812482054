import { __assign, __rest } from "tslib";
import './index.scss';
import * as React from 'react';
import classNames from 'classnames';
import { useResizeObserver } from './hooks/use-resize-observer';
export var CnPlaceholder = function (props) {
    var _a = props.children, children = _a === void 0 ? 'PLACEHOLDER' : _a, otherProps = __rest(props, ["children"]);
    var containerRef = React.useRef(null);
    var size = useResizeObserver(containerRef);
    // 宽度或者高度大于 60
    var isBig = size ? size.width >= 60 || size.height >= 60 : undefined;
    var imgUrl = '';
    if (isBig) {
        imgUrl =
            'https://img.alicdn.com/imgextra/i1/O1CN01655VI01dXG2sAsDMB_!!6000000003745-55-tps-1400-780.svg';
    }
    else if (isBig === false) {
        imgUrl =
            'https://img.alicdn.com/imgextra/i4/O1CN01vuim9S24TaV8NgHo8_!!6000000007392-55-tps-20-20.svg';
    }
    return (React.createElement("div", __assign({ "data-name": "CnPlaceholder", ref: containerRef, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-placeholder-wrapper') }, otherProps),
        React.createElement("div", { className: "cn-ui-placeholder" },
            imgUrl ? (React.createElement("img", { className: "cn-ui-placeholder-img", src: imgUrl, draggable: false })) : null,
            isBig ? (React.createElement("div", { className: "cn-ui-placeholder-text-container" },
                React.createElement("div", { className: "cn-ui-placeholder-text" }, children))) : null)));
};
