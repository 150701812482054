import $i18n, { pandaEvent } from 'panda-i18n';
import React, { useEffect, useState } from 'react';
import { ConfigProvider } from '../fusion';
import nextLocale from '../../locales/cn-next';
// next组件的扩展语种支持
if (window._PANDA_EXUI) {
    Object.keys(window._PANDA_EXUI).forEach(function (lang) {
        if (nextLocale[lang]) {
            // 组件以一维平铺，里面会包含cn组件
            Object.assign(nextLocale[lang], window._PANDA_EXUI[lang]);
        }
        else {
            nextLocale[lang] = window._PANDA_EXUI[lang];
        }
    });
}
var getNextLocale = function (lang) {
    var theLang = lang || $i18n.getLang();
    // fallback to en-US
    var usedLang = nextLocale[theLang] ? theLang : 'en-US';
    return nextLocale[usedLang];
};
/** 该组件为一个configProvider，移值自panda-i18n，提供fusion组件国际化翻译能力 */
export var NextI18nConfigProvider = function (_a) {
    var children = _a.children;
    var _b = useState(getNextLocale()), currentLangNextLocale = _b[0], setCurrentLangNextLocale = _b[1];
    useEffect(function () {
        pandaEvent.on('CHANGE_LANG', function (lang) {
            setCurrentLangNextLocale(getNextLocale(lang));
        });
    }, []);
    return (React.createElement(ConfigProvider, { locale: currentLangNextLocale },
        React.createElement(React.Fragment, null, children)));
};
