import { __assign } from "tslib";
import './array-table.scss';
import $i18n from 'panda-i18n';
import { CnIcon } from '@/components/cn-icon';
import React, { useCallback, useRef } from 'react';
import { formilyReact, formilyShared, } from '@/form/formily';
import { Table as FusionTable } from '@fusion/lib';
import Table from 'rc-table';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import isNil from 'lodash/isNil';
import { CnArrayTablePagination } from './array-table-pagination';
import { isAdditionComponent, isColumnComponent, isOperationsComponent, } from '../utils';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnFormArrayBase, mixinCnFormArrayBase, } from '@/form/cn-form-array-base';
import classNames from 'classnames';
var observer = formilyReact.observer, RecursionField = formilyReact.RecursionField, useField = formilyReact.useField, useFieldSchema = formilyReact.useFieldSchema;
var isArr = formilyShared.isArr, isBool = formilyShared.isBool;
var useAddition = function () {
    var schema = useFieldSchema();
    return schema.mapProperties(function (_schema, key) {
        return React.createElement(RecursionField, { schema: _schema, name: key });
    });
};
var omit = function (props, keys) {
    return Object.keys(props)
        .filter(function (key) { return !(keys === null || keys === void 0 ? void 0 : keys.includes(key)); })
        .reduce(function (buf, key) {
        buf[key] = props[key];
        return buf;
    }, {});
};
var useArrayTableSources = function () {
    var arrayField = useField();
    var schema = useFieldSchema();
    var parseSources = function (_schema) {
        var _a, _b, _c;
        if (isColumnComponent(_schema) ||
            isOperationsComponent(_schema) ||
            isAdditionComponent(_schema)) {
            if (!((_a = _schema['x-component-props']) === null || _a === void 0 ? void 0 : _a.dataIndex) && !_schema.name)
                return [];
            var name = ((_b = _schema['x-component-props']) === null || _b === void 0 ? void 0 : _b.dataIndex) || _schema.name;
            var field = arrayField.query(arrayField.address.concat(name)).take();
            var columnProps = ((_c = field === null || field === void 0 ? void 0 : field.component) === null || _c === void 0 ? void 0 : _c[1]) || _schema['x-component-props'] || {};
            var display = (field === null || field === void 0 ? void 0 : field.display) || _schema['x-display'];
            return [
                {
                    name: name,
                    display: display,
                    field: field,
                    schema: _schema,
                    columnProps: columnProps,
                },
            ];
        }
        else if (_schema.properties) {
            return _schema.reduceProperties(function (buf, schemaItem) {
                return buf.concat(parseSources(schemaItem));
            }, []);
        }
    };
    var parseArrayItems = function (_schema) {
        if (!_schema)
            return [];
        var sources = [];
        var items = isArr(_schema) ? _schema : [_schema];
        return items.reduce(function (columns, schemaItem) {
            var item = parseSources(schemaItem);
            if (item) {
                return columns.concat(item);
            }
            return columns;
        }, sources);
    };
    return parseArrayItems(schema.items);
};
var getValidatorHasRequired = function (validatorSchema) {
    // 兼容 [{required: true}]
    if (Array.isArray(validatorSchema)) {
        var hasItem = validatorSchema.find(function (item) { return item.required; });
        return !!hasItem;
    }
    // 兼容 {required: true}
    if (validatorSchema && validatorSchema.required) {
        return true;
    }
    return false;
};
var findPathIndex = function (value, record) {
    if (!value || !value.length)
        return;
    var traverse = function (v, r) {
        var _a;
        for (var i = 0; i < v.length; i++) {
            if (v[i] === r) {
                return i;
            }
            else if ((_a = v[i].children) === null || _a === void 0 ? void 0 : _a.length) {
                var path = traverse(v[i].children, r);
                if (!isNil(path)) {
                    return "".concat(i, ".children.").concat(path);
                }
            }
        }
    };
    return traverse(value, record);
};
var useArrayTableColumns = function (field, sources, isRcTable) {
    return sources.reduce(function (buf, _a, key) {
        var name = _a.name, columnProps = _a.columnProps, schema = _a.schema, display = _a.display;
        if (display !== 'visible')
            return buf;
        if (!isColumnComponent(schema))
            return buf;
        var tableColumnTitleRender = function () {
            var _a, _b;
            var itemSchema = (_a = Object.values((schema === null || schema === void 0 ? void 0 : schema.properties) || {})[0]) === null || _a === void 0 ? void 0 : _a.toJSON();
            return (React.createElement("div", { className: "cn-ui-formily-array-table-column-title" },
                ((itemSchema === null || itemSchema === void 0 ? void 0 : itemSchema.required) ||
                    getValidatorHasRequired(itemSchema === null || itemSchema === void 0 ? void 0 : itemSchema['x-validator'])) && (React.createElement("span", { className: "cn-ui-formily-array-table-column-title-header" }, "*")),
                React.createElement("span", { className: "cn-ui-formily-array-table-column-title-header-body" }, columnProps === null || columnProps === void 0 ? void 0 : columnProps.title),
                ((_b = itemSchema === null || itemSchema === void 0 ? void 0 : itemSchema['x-decorator-props']) === null || _b === void 0 ? void 0 : _b.tip) && (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { className: "cn-ui-formily-array-table-column-title-footer", type: "help", size: "small" }) }, itemSchema['x-decorator-props'].tip))));
        };
        var column = __assign(__assign({}, columnProps), { key: key, title: tableColumnTitleRender(), dataIndex: name });
        if (isRcTable) {
            column.render = function (value, record) {
                var _a;
                var index = (_a = field.value) === null || _a === void 0 ? void 0 : _a.indexOf(record);
                var findPath = findPathIndex(field.value, record);
                var children = (React.createElement(CnFormArrayBase.Item, { key: index, index: index, record: function () { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                    React.createElement(RecursionField, { schema: schema, name: findPath, onlyRenderProperties: true })));
                return children;
            };
        }
        else {
            column.cell = function (value, _, record) {
                var _a;
                var index = (_a = field.value) === null || _a === void 0 ? void 0 : _a.indexOf(record);
                var children = (React.createElement(CnFormArrayBase.Item, { key: index, index: index, record: function () { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                    React.createElement(RecursionField, { schema: schema, name: index })));
                return children;
            };
        }
        return buf.concat(column);
    }, []);
};
var SortableRow = SortableElement(function (props) { return React.createElement("tr", __assign({}, props)); });
var SortableBody = SortableContainer(function (props) { return React.createElement("tbody", __assign({}, props)); });
var RowComp = function (props) {
    return React.createElement(SortableRow, __assign({ index: props['data-row-key'] || 0 }, props));
};
var CnArrayTableView = observer(function (props) {
    var _a;
    var _b = props.shape, shape = _b === void 0 ? 'borderless' : _b;
    var ref = useRef();
    var field = useField();
    var dataSource = Array.isArray(field.value) ? field.value.slice() : [];
    var sources = useArrayTableSources();
    var isRcTable = (_a = props.isRcTable) !== null && _a !== void 0 ? _a : true;
    var columns = useArrayTableColumns(field, sources, isRcTable);
    var pagination = isBool(props.pagination) ? {} : props.pagination;
    var addition = useAddition();
    var prefixCls = 'cn-ui-formily-array-table';
    var defaultRowKey = function (record) {
        // return dataSource.indexOf(record);
        return findPathIndex(dataSource, record);
    };
    var addTdStyles = function (node) {
        var helper = document.body.querySelector(".".concat(prefixCls, "-sort-helper"));
        if (helper) {
            var tds_1 = node.querySelectorAll('td');
            requestAnimationFrame(function () {
                helper.querySelectorAll('td').forEach(function (td, index) {
                    if (tds_1[index]) {
                        td.style.width = getComputedStyle(tds_1[index]).width;
                    }
                });
            });
        }
    };
    var WrapperComp = useCallback(function (_props) { return (React.createElement(SortableBody, __assign({ useDragHandle: true, lockAxis: "y", helperClass: "".concat(prefixCls, "-sort-helper"), helperContainer: function () {
            var _a;
            return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.querySelector('tbody');
        }, onSortStart: function (_a) {
            var node = _a.node;
            addTdStyles(node);
        }, onSortEnd: function (_a) {
            var oldIndex = _a.oldIndex, newIndex = _a.newIndex;
            field.move(oldIndex, newIndex);
        } }, _props))); }, []);
    var renderTableComp = function (_dataSource) {
        if (isRcTable) {
            return (React.createElement(Table, __assign({ prefixCls: classNames(CN_UI_HASH_CLASS_NAME, prefixCls), rowKey: defaultRowKey, emptyText: function () { return (React.createElement("div", { className: "".concat(prefixCls, "-empty") }, $i18n.get({
                    id: 'NoData',
                    dm: '无数据',
                    ns: 'CnForm',
                }))); } }, props, { columns: columns, data: _dataSource, components: {
                    body: {
                        wrapper: WrapperComp,
                        row: RowComp,
                    },
                } })));
        }
        return (React.createElement(FusionTable, __assign({ emptyContent: $i18n.get({
                id: 'NoData',
                dm: '无数据',
                ns: 'CnForm',
            }), size: "small" }, omit(props, ['value', 'onChange', 'pagination']), { columns: columns, dataSource: _dataSource })));
    };
    return (React.createElement(CnArrayTablePagination, __assign({ "data-name": "CnArrayTable" }, pagination, { dataSource: dataSource }), function (_dataSource, pager) { return (React.createElement("div", { ref: ref, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-array-table', {
            'cn-ui-array-table--borderless': shape === 'borderless',
        }) },
        React.createElement(CnFormArrayBase, null,
            renderTableComp(_dataSource),
            sources.map(function (column, key) {
                // 专门用来承接对Column的状态管理
                if (!isColumnComponent(column.schema))
                    return;
                return React.createElement(RecursionField, {
                    name: column.name,
                    schema: column.schema,
                    onlyRenderSelf: true,
                    key: key,
                });
            }),
            pager,
            addition.length ? (React.createElement("div", { className: classNames('cn-ui-array-table-footer') }, addition)) : null))); }));
});
export var CnArrayTable = mixinCnFormArrayBase(CnArrayTableView);
CnArrayTable.displayName = 'CnArrayTable';
CnArrayTable.Column = Table.Column;
CnArrayTable.ColumnGroup = Table.ColumnGroup;
CnArrayTable.Summary = Table.Summary;
