import $i18n from 'panda-i18n';
export var getJobCountDesc = function (data) {
    var result = [];
    if (Number.isInteger(data.successCount)) {
        result.push($i18n.get({
            id: 'SuccessDataSuccessCount',
            dm: '成功 {dataSuccessCount} 条',
            ns: 'CnAsyncJobsPro',
        }, { dataSuccessCount: data.successCount }));
    }
    if (Number.isInteger(data.errorCount)) {
        result.push($i18n.get({
            id: 'FailedDataErrorCount',
            dm: '失败 {dataErrorCount} 条',
            ns: 'CnAsyncJobsPro',
        }, { dataErrorCount: data.errorCount }));
    }
    return result;
};
